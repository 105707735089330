import { Children, ForwardedRef, forwardRef, PropsWithChildren } from 'react'
import { Listbox as ListboxUI } from '@headlessui/react'

import ArrowUpIcon from '../../../icons/ArrowUpIcon.tsx'
import ArrowDownIcon from '../../../icons/ArrowDownIcon.tsx'
import { getMergedClasses } from '../helpers/getMergedClasses.ts'
import styles from '../Listbox.module.scss'
import { SelectValue } from '../../types/selectValue.ts'
import { ListBoxClasses, ListBoxTexts } from '../../types/listbox.ts'
import { getDataTestId } from 'helpers/getDataTestId.ts'
import { COLORS } from 'globals/colors.ts'

type ListboxViewProps<T extends boolean> = {
    selectedValues: SelectValue[] | SelectValue | null
    onChange: T extends true ? (value: SelectValue | null) => void : (value: SelectValue[]) => void
    multiple: T
    label?: string
    ref: ForwardedRef<HTMLButtonElement>
    placeholder: string
    classes?: ListBoxClasses
    isDisabled?: boolean
    texts?: ListBoxTexts
    dataTestId?: string
}

const ListboxView = forwardRef(
    <T extends boolean>(
        {
            selectedValues,
            placeholder,
            label,
            onChange,
            isDisabled = false,
            multiple,
            children,
            classes,
            texts,
            dataTestId,
        }: PropsWithChildren<ListboxViewProps<T>>,
        ref: ForwardedRef<HTMLButtonElement>,
    ) => {
        const isEmpty = Children.count(children) === 0
        const mergedClasses = getMergedClasses({ classes, isDisabled })

        return (
            <ListboxUI
                by="value"
                value={selectedValues}
                onChange={onChange}
                multiple={multiple}
                name="select"
            >
                {({ open }) => (
                    <>
                        <div className={styles.ButtonWrapper}>
                            {label && (
                                <ListboxUI.Label className={mergedClasses.label}>
                                    {label}
                                </ListboxUI.Label>
                            )}
                            <ListboxUI.Button
                                ref={ref}
                                className={mergedClasses.buttons}
                                data-testid={getDataTestId('select-button', dataTestId)}
                            >
                                <span
                                    className={mergedClasses.placeholder}
                                    data-testid={getDataTestId('select-placeholder', dataTestId)}
                                >
                                    {placeholder}
                                </span>
                                {open ? (
                                    <ArrowUpIcon
                                        color={COLORS.NEUTRAL_10}
                                        className={styles.Icon}
                                    />
                                ) : (
                                    <ArrowDownIcon
                                        color={COLORS.NEUTRAL_10}
                                        className={styles.Icon}
                                    />
                                )}
                            </ListboxUI.Button>
                        </div>
                        <ListboxUI.Options className={mergedClasses.listboxOptions}>
                            {isEmpty ? (
                                <div className={styles.NoOptions}>
                                    {texts?.noOptions || 'No options'}
                                </div>
                            ) : (
                                children
                            )}
                        </ListboxUI.Options>
                    </>
                )}
            </ListboxUI>
        )
    },
)

export default ListboxView
