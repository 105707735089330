export const COLORS = {
    PRIMARY_BLUE: '#55abe0',
    PRIMARY_GREEN: '#5cd497',
    PRIMARY_RED: '#ff5279',
    PRIMARY_ORANGE: '#fd7e14',
    PRIMARY_YELLOW: '#ffae22',
    PRIMARY_PURPLE: '#976fe6',
    PRIMARY_GRADIENT: 'linear-gradient(90deg, #ff245f 0%, #ff7917 100%)',
    BLUE_1: '#eef7fc',
    BLUE_2: '#d4eaf7',
    BLUE_3: '#aad5ef',
    BLUE_4: '#77bce6',
    BLUE_6: '#2488bb',
    BLUE_7: '#006696',
    BLUE_8: '#004674',
    BLUE_9: '#002953',
    GREEN_1: '#effbf5',
    GREEN_2: '#d6f4e5',
    GREEN_3: '#ade9cb',
    GREEN_4: '#7dddac',
    GREEN_6: '#29a96f',
    GREEN_7: '#00804a',
    GREEN_8: '#005827',
    GREEN_9: '#003303',
    RED_1: '#ffeef2',
    RED_2: '#ffd4dd',
    RED_3: '#ffa8bc',
    RED_4: '#ff7594',
    RED_6: '#d9295b',
    RED_7: '#B3003F',
    RED_8: '#8e0026',
    RED_9: '#69000b',
    ORANGE_1: '#fff2e8',
    ORANGE_2: '#fedfc4',
    ORANGE_3: '#febe89',
    ORANGE_4: '#fd9843',
    ORANGE_6: '#d25b00',
    ORANGE_7: '#a83900',
    ORANGE_8: '#811200',
    ORANGE_9: '#5e0000',
    YELLOW_1: '#fff7e9',
    YELLOW_2: '#ffebc8',
    YELLOW_3: '#ffd690',
    YELLOW_4: '#ffbe4e',
    YELLOW_6: '#cf8500',
    YELLOW_7: '#a05f00',
    YELLOW_8: '#743a00',
    YELLOW_9: '#4f1700',
    PURPLE_1: '#f5f1fc',
    PURPLE_2: '#e5dbf9',
    PURPLE_3: '#cbb7f2',
    PURPLE_4: '#ac8ceb',
    PURPLE_6: '#7853c6',
    PURPLE_7: '#5938a7',
    PURPLE_8: '#391e89',
    PURPLE_9: '#12036c',
    NEUTRAL_1: '#fff',
    NEUTRAL_2: '#fafafb',
    NEUTRAL_3: '#f2f2f4',
    NEUTRAL_4: '#e6e6e9',
    NEUTRAL_5: '#ccccd2',
    NEUTRAL_6: '#b3b3bc',
    NEUTRAL_7: '#80808f',
    NEUTRAL_8: '#666679',
    NEUTRAL_9: '#4d4d63',
    NEUTRAL_10: '#33334d',
}

export type Colors = (typeof COLORS)[keyof typeof COLORS]
