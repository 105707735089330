import clsx from 'clsx'
import styles from '../Listbox.module.scss'
import { ListBoxClasses } from '../../types/listbox.ts'

type MergedClassesProps = {
    classes?: ListBoxClasses
    isDisabled: boolean
}

export const getMergedClasses = ({ classes, isDisabled }: MergedClassesProps) => ({
    buttons: clsx(styles.Button, isDisabled && styles.Button__disabled, classes?.Button),
    listboxOptions: clsx(styles.ListboxOptions, classes?.ListboxOptions),
    placeholder: clsx(styles.Placeholder, classes?.Placeholder),
    paper: clsx(styles.Paper, classes?.Paper),
    label: clsx(styles.Label, classes?.Label),
})
