import { ForwardedRef, forwardRef } from 'react'
import MultipleOptionsListbox from './MultipleOptionsListbox/MultipleOptionsListbox.tsx'
import SingleOptionsListbox from './SingleOptionsListbox/SingleOptionsListbox.tsx'
import { ListboxProps } from '../types/listbox.ts'

const Listbox = forwardRef(
    (
        { value, onChange, multiple, children, ...props }: ListboxProps,
        ref: ForwardedRef<HTMLButtonElement>,
    ) => {
        if (multiple) {
            return (
                <MultipleOptionsListbox
                    {...props}
                    value={value}
                    multiple={multiple}
                    onChange={onChange}
                    ref={ref}
                >
                    {children}
                </MultipleOptionsListbox>
            )
        }
        return (
            <SingleOptionsListbox
                {...props}
                value={value}
                onChange={onChange}
                ref={ref}
                multiple={false}
            >
                {children}
            </SingleOptionsListbox>
        )
    },
)

export default Listbox
