import { ReactNode } from 'react'
import clsx from 'clsx'
import styles from './ListboxSubheader.module.scss'

type ListboxSubheaderProps = {
    children: ReactNode
    className?: string
} & React.HTMLProps<HTMLLIElement>

const ListboxSubheader = ({ children, className, ...rest }: ListboxSubheaderProps) => {
    const mergedClasses = clsx(styles.ListboxSubheader, className)
    return (
        <li className={mergedClasses} {...rest}>
            {children}
        </li>
    )
}

export default ListboxSubheader
