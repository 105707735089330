import { ForwardedRef, forwardRef, useEffect, useState } from 'react'

import { SelectValue } from '../../types/selectValue.ts'
import { SingleListboxProps } from '../../types/listbox.ts'
import ListboxView from '../ListboxView/ListboxView.tsx'

const SingleOptionsListbox = forwardRef(
    (
        { value, onChange, placeholder, children, ...props }: SingleListboxProps,
        ref: ForwardedRef<HTMLButtonElement>,
    ) => {
        const [selectedValue, setSelectedValue] = useState<SelectValue | null>(value)
        const isOptionSelected = selectedValue !== null

        const handleSelect = (value: SelectValue | null) => {
            setSelectedValue(value)
            onChange(value)
        }

        useEffect(() => {
            setSelectedValue(value)
        }, [value])

        return (
            <ListboxView
                {...props}
                selectedValues={selectedValue}
                onChange={handleSelect}
                multiple={false}
                ref={ref}
                placeholder={
                    isOptionSelected ? selectedValue.name : placeholder || 'No value is selected'
                }
            >
                {children}
            </ListboxView>
        )
    },
)

export default SingleOptionsListbox
