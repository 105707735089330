import { ForwardedRef, forwardRef, useEffect, useState } from 'react'

import { MultipleListboxProps } from '../../types/listbox.ts'
import { getSelectedOptionsForSelectWithSelectAll } from '../helpers/getSelectedOptionsForSelectWithSelectAll.ts'
import ListboxView from '../ListboxView/ListboxView.tsx'
import { SelectValue } from '../../types/selectValue.ts'
import { getPlaceholderContent } from '../helpers/getPlaceholderContent.ts'

const MultipleOptionsListbox = forwardRef(
    (
        {
            value,
            onChange,
            allValues = [],
            placeholder,
            texts,
            children,
            ...props
        }: MultipleListboxProps,
        ref: ForwardedRef<HTMLButtonElement>,
    ) => {
        const [selectedValues, setSelectedValues] = useState<SelectValue[]>(value)
        const isOptionSelected = selectedValues.length > 0
        const placeholderContent = getPlaceholderContent(
            selectedValues,
            isOptionSelected,
            placeholder,
            texts,
        )

        const handleSelect = (newValues: SelectValue[]) => {
            let selectedItems = newValues
            if (allValues && newValues && selectedValues) {
                selectedItems = getSelectedOptionsForSelectWithSelectAll({
                    prevOptions: selectedValues,
                    newOptions: newValues,
                    allOptions: allValues,
                })
            }
            setSelectedValues(selectedItems)
            onChange(selectedItems)
        }

        useEffect(() => {
            setSelectedValues(value)
        }, [value])

        return (
            <ListboxView
                {...props}
                selectedValues={selectedValues}
                onChange={handleSelect}
                ref={ref}
                placeholder={placeholderContent}
            >
                {children}
            </ListboxView>
        )
    },
)

export default MultipleOptionsListbox
