import { Listbox } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, ReactNode, SyntheticEvent } from 'react'
import styles from './ListboxOption.module.scss'
import CheckIcon from 'components/icons/CheckIcon'
import { SelectValue } from '../types/selectValue.ts'
import { getDataTestId } from 'helpers/getDataTestId.ts'
import { COLORS } from 'globals/colors.ts'

type OnClickProps<T> = {
    value: T
    e: SyntheticEvent
}

type ListboxOptionProps<T> = {
    value: T
    children: ReactNode
    className?: string
    onClick?: ({ value, e }: OnClickProps<T>) => void
    dataTestId?: string
}

const ListboxOption = <T extends SelectValue>({
    value,
    children,
    className,
    onClick,
    dataTestId,
}: ListboxOptionProps<T>) => {
    const mergedClasses = clsx(styles.ListboxOption, className)
    return (
        <Listbox.Option value={value} as={Fragment}>
            {({ active, selected }) => {
                return (
                    <li
                        onClick={e => {
                            onClick?.({ value, e })
                        }}
                        className={clsx(
                            selected && styles.ListboxOption__selected,
                            active && styles.ListboxOption__active,
                            mergedClasses,
                        )}
                        data-testid={getDataTestId('select-option', dataTestId)}
                    >
                        {children}
                        {selected && (
                            <CheckIcon
                                className={styles.CheckIcon}
                                color={COLORS.NEUTRAL_10}
                                size={15}
                            />
                        )}
                    </li>
                )
            }}
        </Listbox.Option>
    )
}

export default ListboxOption
