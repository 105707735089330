import { IconProps } from './Icon.types'

const CheckIcon = ({ size = 25, className, color }: IconProps) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M0.478516 7.99794L5.74061 13.26L14.5107 2.73584"
                stroke={color}
                strokeWidth="0.996"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default CheckIcon
