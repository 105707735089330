import { SelectValue } from '../../types/selectValue.ts'

const ALL = 'all'

type SelectedOptionsForSelectWithSelectAllProps = {
    prevOptions: SelectValue[]
    newOptions: SelectValue[]
    allOptions: SelectValue[]
}

export const getSelectedOptionsForSelectWithSelectAll = ({
    prevOptions,
    newOptions,
    allOptions,
}: SelectedOptionsForSelectWithSelectAllProps) => {
    const doesPrevOptionsContainSelectAll = prevOptions.some(option => option.value === ALL)
    const doesNewOptionsContainSelectAll = newOptions.some(option => option.value === ALL)

    if (doesPrevOptionsContainSelectAll && !doesNewOptionsContainSelectAll) {
        return []
    }
    if (!doesPrevOptionsContainSelectAll && doesNewOptionsContainSelectAll) {
        return allOptions
    }
    if (newOptions.length < prevOptions.length) {
        return newOptions.filter(option => option.value !== ALL)
    }
    if (newOptions.length === allOptions.length - 1) {
        return allOptions
    }

    return newOptions
}
