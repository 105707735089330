import { SelectValue } from '../../types/selectValue.ts'
import { ListBoxTexts } from '../../types/listbox.ts'

export const getPlaceholderContent = (
    selectedValues: SelectValue[],
    isOptionSelected: boolean,
    placeholderText?: string,
    texts?: ListBoxTexts,
) => {
    if (!isOptionSelected || selectedValues.length === 0) {
        return placeholderText || 'No value is selected'
    }
    if (selectedValues.length === 1) {
        return selectedValues[0].name
    }
    if (selectedValues.some(option => option.value === 'all')) {
        return texts?.allOptionsSelected || 'All options'
    }
    return texts?.multipleItemsSelected || 'Multiple options'
}
